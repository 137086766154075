






















































































































































































































































































































































































































































































































































































































































































































































































































.item-width  {
    width: 48%;
    color:#409EFF;
}
::v-deep .el-table {
    th {
        padding: 0px ;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
}
